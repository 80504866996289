<!-- <section>
  <div class="safari-animation"></div>
</section> -->
<div
  id="safari {{ _breakpoint() }}"
  [ngClass]="{
    Large: _breakpoint() === 'Large',
    Desktop: _breakpoint() === 'Desktop',
    iPadPro: _breakpoint() === 'iPadPro',
    iPad: _breakpoint() === 'iPad',
    iPhone: _breakpoint() === 'iPhone',
  }">
  <section class="safari-intro">
    @if (!is404) {
      <div class="safari-intro-content">
        <img
          src="/assets/safaris/Olifant-NoTummy.png"
          class="safari-intro-image"
          alt="Guided Safaris, Into Botswana private journeys" />
        <div class="safaris-intro">
          <h1>The finest Botswana Safaris</h1>
          <div class="intro-content">
            As one of the world’s most respected brands for truly Private Travel
            experiences, Guided Safaris® is the original luxury safari company
            to exclusively to offer completely personalized and private luxury
            African safaris. Guided Safaris® have been specializing for over 30
            years in building strong working relationships with key industry
            suppliers and premier camps to bring you truly Private Safaris. With
            our key focus on privacy, you never travel as part of a group, ever.
          </div>
          <div class="intro-tagline">
            <span class="intro-tagline-text"
              >Unspoilt, uncrowded, unfiltered.</span
            >
          </div>
        </div>
      </div>
      @if (_breakpoint() === 'Desktop' || _breakpoint() === 'Large') {
        <img src="/assets/safaris/lalune.png" class="image-a" />
        <img src="/assets/safaris/tri-vert.png" class="image-b" />
      }
      @if (_breakpoint() !== 'iPhone') {
        <img src="/assets/safaris/tri-gauche.png" class="image-c" />
      }

      <div class="half-circle"></div>
    } @else {
      <div class="safari-intro-content">
        <img
          src="/assets/safaris/OhLaLa.png"
          class="safari-intro-image"
          alt="Guided Safaris, Into Botswana private journeys" />
        <div class="safaris-intro">
          <h1>OMG</h1>
          <div class="intro-content error">
            Looks like our server tents had some unexpected visitors who moved
            stuff around, because this page wasn’t found...
            <br /><br />Meanwhile, please continue browsing the Guided Safaris®
            site with these safari library sections to help lead you to what
            you're looking for...
          </div>
          <div class="intro-tagline error">
            <span class="intro-tagline-text">Lost in the Woods</span>
          </div>
        </div>
      </div>
    }
  </section>

  <mat-toolbar class="filters-bar filters-spacer" [class]="_breakpoint()">
    <div class="filters-intro type2-body-2">Botswana Luxury Safaris</div>
  </mat-toolbar>
  <!-- <div id="anime-1" class="chap-1-2__animation chap-1-2-camp-animation">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 483.61 775.11"
    class="anime-svg">
    <defs>
      <style>
        .cls-1 {
          fill: #e58687;
        }
        .cls-2 {
          fill: #e58587;
        }
        .cls-3 {
          stroke-width: 0.75px;
        }
        .cls-3,
        .cls-4 {
          fill: none;
          stroke: #e58587;
          stroke-miterlimit: 10;
        }
      </style>
    </defs>
    <g id="orange">
      <path
        class="cls-1"
        d="M482.82,2.85c-2.94,.62-5.87,1.34-8.79,2.05-8.12,1.99-16.19,4.15-24.23,6.43-12.4,3.51-24.71,7.31-36.95,11.35-15.57,5.14-31.01,10.68-46.3,16.61-17.98,6.97-35.74,14.48-53.25,22.55-19.11,8.81-37.91,18.29-56.3,28.5-19.79,10.99-39.12,22.83-57.8,35.62s-37.7,27.3-55.25,42.57c-18.02,15.67-35.02,32.53-50.6,50.64-15.61,18.14-29.79,37.53-42.07,58.08-12.67,21.2-23.28,43.62-31.49,66.92-8.74,24.8-14.7,50.55-17.89,76.65-1.48,12.12-2.63,24.17-1.39,36.36,1.01,9.95,3.21,19.78,6.6,29.2,6.39,17.74,16.96,33.67,29.84,47.36,13.42,14.27,29.18,26.21,45.7,36.64,17.17,10.84,35.29,20.12,53.68,28.71,17.4,8.13,35.08,15.6,52.68,23.27,14.82,6.47,29.73,12.97,43.79,21,9.66,5.51,20.2,12.05,26.24,21.7,5.17,8.26,4.31,17.44-.64,25.59-3.36,5.52-7.92,10.24-12.69,14.55-.64,.58,.78,.66,1.18,.3,7.86-7.09,16.71-16.48,17.26-27.71,.45-9.29-5.52-17-12.17-22.81-10-8.74-22.17-14.96-34.04-20.73-15.92-7.74-32.27-14.57-48.5-21.61-18.2-7.9-36.33-15.98-53.98-25.06-17.87-9.19-35.31-19.39-51.36-31.51-15.45-11.66-29.66-25.24-40.65-41.25-11.03-16.07-18.34-34.42-20.97-53.75-1.66-12.21-.94-24.13,.42-36.34,1.48-13.29,3.66-26.5,6.57-39.56,5.58-25.02,13.85-49.42,24.6-72.69s22.83-43.49,37.15-63.42c14.08-19.6,29.88-37.95,46.92-55.04s34.75-32.44,53.57-46.93c18.22-14.02,37.23-27.01,56.8-39.06,18.82-11.58,38.16-22.31,57.88-32.26,18.09-9.12,36.52-17.6,55.19-25.47,16.47-6.94,33.14-13.42,49.98-19.43,13.96-4.98,28.03-9.65,42.21-13.97,10.14-3.09,20.34-6.01,30.6-8.7,5.5-1.44,11.02-2.83,16.56-4.09,.7-.16,1.4-.32,2.11-.47,.89-.19,.67-1-.22-.82h0Z" />
    </g>
    <g id="Layer_5">
      <path class="cls-4" d="M240.78,709.06c2.14-1.13,4.14-2.5,5.96-4.1" />
      <path class="cls-3" d="M240.23,704.76l-.46,2.89" />
      <path
        class="cls-2"
        d="M235.35,707.08c1.95,.67,6.89,1.05,6.39,4.2-1.05,2.46-5.67-3.11-6.39-4.2h0Zm.04,.03c.98,.55,5.44,5.71,6.09,4.07,.57-1.42-1.08-2.35-2.29-2.57-1.26-.35-2.34-.95-3.81-1.5h0Z" />
      <path
        class="cls-2"
        d="M237.81,710.8c-.04,.65,0,1.11-.02,1.5,0,.41-.29,.68-.37,1.08,0,0-.09-.14-.09-.14,.25,.07,.47,.24,.57,.52l-.24-.14c.44-.32,.45-.68,.37-1.17-.04-.36-.14-.83-.2-1.21-.02-.1,.06-.22-.02-.43h0Zm-.01-.07c1.21,.77,1.66,3.22-.3,3.27,.11-.28,0-.53-.33-.54,.07-.28,.04-.54,.05-.86-.05-.61,.37-1.5,.59-1.87h0Z" />
      <path
        class="cls-2"
        d="M235.03,708.77c.11,.23,.34,.57,.54,1,.18,.45,.19,.96,.43,1.38-1.25,1.15-1.32-1.74-.96-2.38h0Zm0,.05c.31,.84,.3,1.31,.44,2.12,.05,.26,.14,.18,.35,.1,0,0-.04,.11-.04,.11-.06-.38-.32-.67-.45-1-.09-.35-.14-.78-.31-1.33h0Z" />
      <path
        class="cls-2"
        d="M232,710.63c.13-.11,.39-.3,.72-.45,.41-.16,.82-.1,1.13-.34-.01,.28,.09,.52,0,.83-.29,.83-1.47,.49-1.85-.04h0Zm.05,0c.39-.01,.74,.02,1.11,0,.33,0,.47-.34,.53-.65,0,0,.12,.08,.12,.08-.31,.05-.49,.3-.72,.37-.26,.07-.58,.09-1.03,.19h0Z" />
      <path
        class="cls-2"
        d="M230.03,712.02c2.18-1.28,6.25-.46,6.12,2.59-.95,3.47-5.35-1.03-6.12-2.59h0Zm.1,.03c1.59,.74,2.97,2.1,4.49,2.86,.49,.2,.77,0,1-.44,.54-1.34-.79-2-1.95-2-1.03-.17-2.53-.26-3.54-.42h0Z" />
      <path
        class="cls-2"
        d="M228.82,712.76c.82,.89,1.63,1.51,2.24,2.2,.52,.59,.96,1.54,1.78,1.8,.1,.03,.27,.04,.26,.02-.03,.07-.05-.06-.12-.14-.44-.53-1.42-1.31-1.94-1.77-.71-.59-1.57-1.46-2.21-2.11h0Zm-.04-.03c1.7,1,3.59,2.17,4.57,3.95,.11,.42-.35,.42-.59,.29-1.56-.96-2.95-2.93-3.98-4.24h0Z" />
      <path
        class="cls-2"
        d="M227.97,715.6c-.13-1-.24-1.91-.29-2.83l.11,.17c-.59-.29-1.37,.27-1.59,.94-.22,.74,.03,1.57,.36,2.3l.15,.33-.28-.24c-.83-.78-2.43-1.5-3.08-.3-.21,.69,.17,1.49,1.06,1.16,.82-.21,2.76-1.18,3.56-1.54h0Zm.04,.02c-1.3,.87-2.59,2-4.21,2.1-.88-.1-1.22-1.2-.9-1.93,.85-1.58,2.73-.59,3.63,.36,0,0-.13,.1-.13,.1-.28-.76-.66-1.54-.52-2.44,.14-.96,1.3-1.8,2.21-1.17,.03,1.01-.01,2.05-.08,2.98h0Z" />
      <path
        class="cls-2"
        d="M225.58,718.75c.32,.62,.13,1.43,.15,2.04-.06,1.54,1.34,1.83,1.99,.52,.24-.6,.11-1.31-.06-1.96l-.11-.4,.33,.27c1.37,1.05,3.2-.53,1.97-1.81-.39-.39-1.24-.08-1.98,.25-.75,.34-1.53,.76-2.29,1.07h0Zm-.07-.03c1.03-.9,2-1.72,3.3-2.18,.44-.15,1.13-.14,1.56,.27,.85,.76,.81,2.12-.02,2.82-.84,.67-2.01,.45-2.65-.2l.22-.13c.17,.99,.46,2.33-.46,3.1-.29,.26-.73,.46-1.2,.36-.47-.09-.82-.47-.96-.86-.45-1.1,.28-2.11,.2-3.19h0Z" />
      <path
        class="cls-2"
        d="M203.38,732.56c2.19-2.16,4.68-3.88,7.25-5.52,4-3.03,7.84-6.75,12.26-8.96-3.66,3.57-8,6.21-12.12,9.16-2.3,1.91-4.75,4.13-7.4,5.33h0Z" />
      <path
        class="cls-2"
        d="M170.53,756c3.22-3.73,7.3-6.57,11.48-9.1,3.96-2.86,7.55-6.24,11.73-8.82,2.76-1.74,5.62-3.33,8.65-4.61l.08,.13c-6.41,5.48-13.42,9.02-20.16,13.7-2.49,2.09-4.82,4.37-7.55,6.17-1.33,.93-2.68,1.85-4.12,2.66l-.09-.12h0Z" />
      <path
        class="cls-2"
        d="M168.97,758.85c6.62-5.4,13.98-9.69,21.36-13.92,4.89-2.99,15.71-11.64,20.46-15.15,0,0,13.59-10.18,13.59-10.18l.12,.13c-5.76,6.32-12.42,11.75-19.49,16.52-4.65,3.26-9.69,5.93-14.36,9.15-4.6,3.31-9.13,6.75-14.09,9.53-2.44,1.44-4.93,2.81-7.49,4.07,0,0-.09-.15-.09-.15h0Z" />
      <path
        class="cls-2"
        d="M176.8,728.68c1.01,3.43,5.16,4.47,8.33,4.72,4.75,.17,8.99-3.31,13.77-2.39,1,.21,2.69,.91,3.05,1.77-4.16-2.92-8.86,.14-13.31,.59-3.45,.36-7.42,.84-10.29-1.56-.92-.78-1.66-1.91-1.63-3.13,0,0,.07,0,.07,0h0Z" />
      <path
        class="cls-2"
        d="M175.38,735.45c1.12,1.58,3.07,2.05,4.88,2.34,4.72,.98,9.41-.65,13.71-2.49,.85-.29,2.07-.62,2.85-.22-1.94-.18-3.49,1.13-5.29,1.77-1.78,.71-3.68,1.15-5.59,1.28-3.44,.06-8.76,.97-10.56-2.69h0Z" />
      <path
        class="cls-2"
        d="M168.78,735.89c7.94,5.19,13.51,4.16,22.37,2.69,0,0,.07,.16,.07,.16-6.66,4.9-17.17,2.62-22.44-2.85h0Z" />
      <path
        class="cls-2"
        d="M168.12,740.17c5.59,3.46,13.45,5.23,19.13,1.14,0,0,.07,.07,.07,.07-4.27,5.89-14.78,2.64-19.2-1.21h0Z" />
      <path
        class="cls-2"
        d="M166.81,744.1c4.65,2.03,9.77,4.06,14.81,2.1,.48-.19,.03-.29-.25-.18-4.66,1.81-9.6-.3-13.88-2.17-.2-.09-.77,.21-.68,.25h0Z" />
      <path
        class="cls-2"
        d="M167.47,747.96c3.66,1.92,8.16,1.81,11.77-.17,.43-.23-.22-.31-.44-.19-3.32,1.82-7.41,1.77-10.75,.02-.19-.1-.86,.19-.58,.34h0Z" />
      <path
        class="cls-2"
        d="M165.8,750.92c.86-.24,1.75-.24,2.61,0s1.58,.64,2.43,.81c1.57,.31,3.32-.25,4.45-1.38,.24-.24-.21-.32-.36-.32-.33,0-.72,.13-.96,.37-.43,.43-.97,.73-1.59,.73-.72,0-1.4-.33-2.05-.58-1.51-.58-3.1-.64-4.66-.2-.17,.05-.72,.22-.65,.48s.65,.13,.8,.09h0Z" />
      <path
        class="cls-2"
        d="M165.96,755.94c.48-.99,1.51-1.67,2.62-1.6,.59,.04,1.15,.12,1.73,0,.03,0,.06-.01,.09-.02,0,0,.02,0,.03,0-.08,.03-.22,.01-.33-.03l-.08-.1h0c.02-.06,.04-.13,.06-.19,.02-.02,.21-.13,.1-.09-.04,.02,.12-.04,.05-.02-.02,0-.04,.02-.06,.02-.17,.06-.35,.12-.52,.19-.7,.26-1.4,.52-2.1,.79s-1.4,.55-2.09,.82c-.13,.05-.28,.21-.18,.35,.1,.15,.36,.09,.49,.04,1.13-.45,2.27-.9,3.41-1.33,.28-.11,.56-.21,.85-.31,.12-.04-.05,.02,.06-.02,.04-.01,.08-.03,.12-.04,.05-.02,.1-.04,.15-.05,.15-.05,.45-.14,.39-.36s-.34-.15-.49-.12c-.03,0-.07,.01-.1,.02-.06,.01-.09-.01,.03,0-.02,0-.05,0-.07,.01-.4,.06-.8-.01-1.2-.04-1.48-.11-2.99,.67-3.65,2.03-.17,.36,.56,.33,.69,.06Z" />
      <path
        class="cls-2"
        d="M170.38,762.75c-.44-.67-.19-1.38,.23-1.94,.23-.63,.24-1.37,.73-1.89,.19-.23,.98-.82,1.25-.63-.41,1.21-1.33,1.68-1.74,2.6,0,.67,.03,1.22-.39,1.85,0,0-.07,0-.07,0h0Z" />
      <path
        class="cls-2"
        d="M206.75,735.07c.08,2.69-1.24,5.12-2.82,7.19-3.8,4.92-6.73,10.53-6.34,16.87-.19,1.19-.27,2.39,.27,3.6-2.32-1.67-2.21-4.91-1.84-7.46,.53-5.23,3.43-9.95,6.48-14.01,1.37-2.18,2.77-4.07,4.26-6.19h0Z" />
      <path
        class="cls-2"
        d="M202.51,738.14c-.67,4.11-3.45,7.33-6.1,10.33-3.53,5.26-6.09,12.02-3.88,18.31,0,0-.13,.08-.13,.08-2.79-3.2-2.61-8.2-.84-11.88,1.07-2.53,3-4.51,4.45-6.79,1.35-2.29,2.61-4.64,4.18-6.78,.74-1.09,1.44-2.19,2.18-3.33,0,0,.14,.05,.14,.05h0Z" />
      <path
        class="cls-2"
        d="M197.1,742.69c-1.52,3.16-4.1,5.67-5.51,8.87-1.36,3.2-2.06,6.59-2.22,10.07,0,0-.05,0-.05,0-.87-5.44,1.88-10.3,4.68-14.73,.96-1.42,1.95-3.05,3.1-4.22h0Z" />
      <path
        class="cls-2"
        d="M192.1,745.37c-.26,3.09-1.7,5.72-3.35,8.23-.87,1.76-.99,3.82-1.38,5.69-.42,2.88-.72,5.5-.32,8.49,0,0-.16,.07-.16,.07-2-2.46-2.12-6.05-1.13-8.95,.53-1.96,1.83-3.61,2.46-5.51,.56-1.9,.89-3.9,2-5.61,.49-.87,1.02-1.72,1.72-2.48l.16,.07h0Z" />
      <path
        class="cls-2"
        d="M188.45,747.78c-1.94,2.99-4.06,5.99-4.83,9.52-.61,3.44-1.36,7.17,.13,10.49,0,0-.04,.03-.04,.03-3.48-4.7,.76-16.58,4.75-20.04h0Z" />
      <path
        class="cls-2"
        d="M183,751.69c-1.4,1.34-2.24,3.01-2.88,4.79-.55,3.15-.03,6.35,.48,9.49,0,0-.07,.03-.07,.03-1.82-2.88-1.42-6.42-.65-9.57,.19-1.95,1.1-4.1,3.08-4.8,0,0,.04,.06,.04,.06h0Z" />
      <path
        class="cls-2"
        d="M178.67,754.43l-.83,2.33c-1.04,2.32-.15,4.88,.37,7.25,0,0-.04,.02-.04,.02-1.34-1.76-1.31-4.06-.88-6.12,.08-1.21,.43-2.72,1.39-3.47h0Z" />
      <path
        class="cls-2"
        d="M175.98,756.18c0,.84-.32,1.39-.68,2.05-.11,1.23,.44,2.3,.24,3.57,0,0-.07,.03-.07,.03-.4-.37-.63-.86-.73-1.37-.18-.78,.2-1.52,.33-2.27-.07-.78,.1-1.6,.85-2.05,0,0,.06,.04,.06,.04h0Z" />
      <path
        class="cls-2"
        d="M173.83,757.97c-.11,.63-.35,1.07-.57,1.62-.04,.97,.45,1.76,.67,2.73,0,0-.04,.03-.04,.03-1.02-.57-1-1.8-.79-2.78-.07-.62,.1-1.29,.69-1.62,0,0,.04,.03,.04,.03h0Z" />
    </g>
  </svg>
</div> -->
  <mat-toolbar class="filters-bar filters-spacer" [class]="_breakpoint()">
    <div class="browse-by-interest">Browse by interest</div>

    <span class="submenu-spacer"></span>

    <button
      mat-button
      color="accent"
      class="filter-buttons"
      (click)="resetSafaris()">
      Clear
    </button>
  </mat-toolbar>

  <div class="filter-section filters-bar filters-spacer">
    <mat-grid-list
      [cols]="filterlist[0].cols"
      [rowHeight]="filterlist[0].rowHeight"
      [gutterSize]="filterlist[0].gutterSize">
      @for (tile of tilesInterest; track tile) {
        <mat-grid-tile
          class="tilesInterestclass"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [formGroup]="interests">
          <mat-checkbox
            [class]="tile.color"
            class="check-margin"
            [(ngModel)]="tile.checked"
            [ngModelOptions]="{ standalone: true }"
            (change)="
              tile.description &&
                onInterestChange(tile.description, $event.checked)
            ">
            <div class="check-desc type2-body-2">
              {{ tile.description }}
            </div>
          </mat-checkbox>
        </mat-grid-tile>
      }
    </mat-grid-list>
  </div>
  <section id="safaris-display" class="safaris-display">
    <div class="chips">
      <mat-chip
        class="each-chip"
        *ngFor="let chip of interestChips"
        [removable]="true"
        (removed)="remove(chip)">
        {{ chip.description }}
        <mat-icon matChipRemove>close</mat-icon>
      </mat-chip>
    </div>
  </section>

  <section id="grid3" class="justify-center other-links safaris-display">
    <div class="section-40-60 max-width">
      <div class="three-grid">
        <div class="chips3">
          @for (safari of filteredSafaris; track safari) {
            <div class="gridtile">
              <a
                [routerLink]="['/safari', safari.fields['slug']]"
                routerLinkActive="active">
                <div
                  class="safari-index safari-thumbnail safari-filter-thumbnail">
                  <img
                    [src]="safari.fields.introImage.fields.file.url"
                    [alt]="safari.fields.introImage.fields.description" /></div
              ></a>
              <div class="safari-name safari-index-name">
                <a [routerLink]="['/safari', safari.fields['slug']]">{{
                  safari.fields['safari'] | uppercase
                }}</a>
              </div>
              <div class="type type3-caption">
                {{ 'Custom private safari' | uppercase }}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </section>

  <section
    id="safari-chap-5-expanded-video"
    class="justify-center f6f1eb"
    style="position: relative">
    <div class="chapter-5-expanded-photo expanded-photo-columns">
      <div class="chapter-V-image">
        <img
          src="/assets/safari/Private-Safari-Villas.webp"
          alt="Guided Safaris, Private Luxury Safaris" />
      </div>
    </div>
  </section>

  <section id="safari-chap-5-expanded-second" class="f6f1eb">
    <div
      class="safari-grid safari-g-chapter chapter-5-expanded2 mat-typography">
      <!-- <div class="chapter-5-expanded-sections"> -->
      <div class="safari--col-l epic-experiences">
        <div
          class="chapter chapter-description chap-5-expanded-left chap-5-expanded-column chap-5-expanded-des">
          ALL YOU NEED IS A DREAM...
        </div>
      </div>
      <div class="safari--col-r safari-chapter wave-expanded">
        <div
          class="chapter chapter-description chap-5-expanded-right expandedh5-index">
          <p>
            Guided Safaris® Private Luxury Villas are the perfect safari escape
            to savor precious time with family and friends in complete seclusion
            away from the crowds in the world’s most beautiful settings. With
            menus tailored to your preferences, sprawling two to eight bedroom
            private lodges and a fully custom Privately Guided experience with a
            host, our Family Safaris are a travel experience like no other. If
            you can dream it, we’ll tailor your perfect wilderness encounter.
          </p>
        </div>

        <div class="wave safari-index-wave">
          <div class="wave-link safari-index-wave-link">
            <a routerLink="/safari/luxury-private-jet-safari">
              <img
                src="/assets/images/DF-Family-4.png"
                alt="Explore a private family safari" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  @if (threegrid) {
    <section id="grid3" class="justify-center other-links">
      <div class="section-40-60 max-width">
        <div class="three-grid">
          <div class="three-header">
            <div>Poetic, wonderful and endless.</div>
          </div>
          <div class="chips3">
            @for (griditem of threegrid; track griditem) {
              <div class="gridtile">
                <div class="safari-thumbnail">
                  <a [routerLink]="griditem.fields.url"
                    ><img
                      [src]="griditem.fields.photo.fields.file.url"
                      [alt]="griditem.fields.photo.fields.title"
                  /></a>
                </div>
                <div class="safari-name">
                  <a [routerLink]="griditem.fields.url">{{
                    griditem.fields.title | uppercase
                  }}</a>
                </div>
                <div class="type type3-caption">
                  {{ griditem.fields.subtitleSecondLine | uppercase }}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  }

  <section id="epic-experiences" class="grid-2-50-grid">
    <div class="grid-2-50-container-grid">
      <div class="grid-2-50-div-left-grid">
        <div
          class="epic-experiences epic-experiences-title"
          style="color: #9c7c3d">
          {{ 'Crafting lasting memories' | uppercase }}
        </div>
      </div>
      <div class="grid-2-50-div-right-grid">
        <div
          class="chapter chapter-description chap-5-expanded-right derived-from-description">
          <p>
            Derived from age-old know-how, our journeys to Africa are tailored
            by your imagination. The dream becomes the enchantment of a moment,
            the joy of a journey, a companion for life. <br /><br />
            Would you like to step aboard?
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
